import { UserMunicipality } from '@shared/models/user-municipality.model';

import { DefaultMunicipality } from "@config/prod/_default.municipality";
import { TonsbergDevMunicipality } from "@config/dev/tonsberg-dev.municipality";
import { ArendalDevMunicipality } from "@config/dev/arendal-dev.municipality";
import { VossDevMunicipality } from "@config/dev/voss-dev.municipality";
import { BergenDevMunicipality } from "@config/dev/bergen-dev.municipality";
import { HopDevMunicipality } from "@config/dev/hop-dev.municipality";
import { FanaDevMunicipality } from "@config/dev/fana-dev.municipality";
import { BydelfrognerDevMunicipality } from "@config/dev/bydelfrogner-dev.municipality";
import { AskerDevMunicipality } from './dev/asker-dev.municipality';

import { TonsbergVisMunicipality } from "@config/vis/tonsberg-vis.municipality";
import { LorenskogVisMunicipality } from "@config/vis/lorenskog-vis.municipality";
import { TrondheimVisMunicipality } from "@config/vis/trondheim-vis.municipality";
import { ArendalVisMunicipality } from "@config/vis/arendal-vis.municipality";
import { TromsoVisMunicipality } from "@config/vis/tromso-vis.municipality";
import { NoreoguvdalVisMunicipality } from './vis/noreoguvdal-vis.municipality';
import { SolaVisMunicipality } from './vis/sola-vis.municipality';
import { BodoVisMunicipality } from "@config/vis/bodo-vis.municipality";

import { KvinnheradMunicipality } from "@config/prod/kvinnherad.municipality";
import { KautokeinoMunicipality } from "@config/prod/kautokeino.municipality";
import { LaerdalMunicipality } from "@config/prod/laerdal.municipality";
import { SnillbuMunicipality } from "@config/prod/snillbu.municipality";
import { SandefjordMunicipality } from "@config/prod/sandefjord.municipality";
import { BodoMunicipality } from "@config/prod/bodo.municipality";
import { VoldaMunicipality } from "@config/prod/volda.municipality";
import { KvinesdalMunicipality } from "@config/prod/kvinesdal.municipality";
import { UlsteinMunicipality } from "@config/prod/ulstein.municipality";
import { TysnesMunicipality } from "@config/prod/tysnes.municipality";
import { SulaMunicipality } from "@config/prod/sula.municipality";
import { NamsosMunicipality } from "@config/prod/namsos.municipality";
import { NaroysundMunicipality } from "@config/prod/naroysund.municipality";
import { HortenMunicipality } from "@config/prod/horten.municipality";
import { BjerkreimMunicipality } from "@config/prod/bjerkreim.municipality";
import { UlvikMunicipality } from "@config/prod/ulvik.municipality";
import { FauskeMunicipality } from "@config/prod/fauske.municipality";
import { RadeMunicipality } from "@config/prod/rade.municipality";
import { KvamMunicipality } from "@config/prod/kvam.municipality";
import { OvreeikerMunicipality } from "@config/prod/ovreeiker.municipality";
import { DrammenMunicipality } from "@config/prod/drammen.municipality";
import { TysverMunicipality } from "@config/prod/tysver.municipality";
import { LevangerMunicipality } from "@config/prod/levanger.municipality";
import { AlesundMunicipality } from "@config/prod/alesund.municipality";
import { VaksdalMunicipality } from "@config/prod/vaksdal.municipality";
import { SunnfjordMunicipality } from "@config/prod/sunnfjord.municipality";
import { OygardenMunicipality } from "@config/prod/oygarden.municipality";
import { TonsbergMunicipality } from "@config/prod/tonsberg.municipality";
import { GjesdalMunicipality } from "@config/prod/gjesdal.municipality";
import { VossMunicipality } from "@config/prod/voss.municipality";
import { StavangerMunicipality } from "@config/prod/stavanger.municipality";
import { TromsoMunicipality } from "@config/prod/tromso.municipality";
import { MoldeMunicipality } from "@config/prod/molde.municipality";
import { MossMunicipality } from "@config/prod/moss.municipality";
import { RalingenMunicipality } from "@config/prod/ralingen.municipality";
import { HaugesundMunicipality } from "@config/prod/haugesund.municipality";
import { ArendalMunicipality } from "@config/prod/arendal.municipality";
import { BjornafjordenMunicipality } from "@config/prod/bjornafjorden.municipality";
import { BaerumMunicipality } from "@config/prod/baerum.municipality";
import { MidtTelemarkMunicipality } from "@config/prod/midt-telemark.municipality";
import { FordeMunicipality } from "@config/prod/forde.municipality";
import { LorenskogMunicipality } from "@config/prod/lorenskog.municipality";
import { InderoyMunicipality } from "@config/prod/inderoy.municipality";
import { KristiansundMunicipality } from "@config/prod/kristiansund.municipality";
import { KristiansandMunicipality } from "@config/prod/kristiansand.municipality";
import { LusterMunicipality } from "@config/prod/luster.municipality";
import { SokndalMunicipality } from "@config/prod/sokndal.municipality";
import { NaustdalMunicipality } from "@config/prod/naustdal.municipality";
import { SkiMunicipality } from "@config/prod/ski.municipality";
import { SveioMunicipality } from "@config/prod/sveio.municipality";
import { SorumMunicipality } from "@config/prod/sorum.municipality";
import { NesoddenMunicipality } from "@config/prod/nesodden.municipality";
import { SenjaMunicipality } from "@config/prod/senja.municipality";
import { FrolandMunicipality } from "@config/prod/froland.municipality";
import { TrondheimMunicipality } from "@config/prod/trondheim.municipality";
import { UllensakerMunicipality } from "@config/prod/ullensaker.municipality";
import { SolaMunicipality } from "@config/prod/sola.municipality";
import { GiskeMunicipality } from "@config/prod/giske.municipality";
import { BydelfrognerMunicipality } from "@config/prod/bydelfrogner.municipality";
import { HaramMunicipality } from './prod/haram.municipality';
import { ModalenMunicipality } from './prod/modalen.municipality';
import { HoyangerMunicipality } from './prod/hoyanger.municipality';
import { SandnesMunicipality } from './prod/sandnes.municipality';
import { NoreoguvdalMunicipality } from './prod/noreoguvdal.municipality';
import { BydelgamleosloMunicipality } from '@config/prod/bydelgamleoslo.municipality';
import { DovreMunicipality } from '@config/prod/dovre.municipality';
import {DyroyMunicipality} from "@config/prod/dyroy.municipality";
import { RandabergMunicipality } from './prod/randaberg.municipality';
import {SamnangerMunicipality} from "@config/prod/samnanger.municipality";
import { DonnaMunicipality } from './prod/donna.municipality';
import { AskerMunicipality } from './prod/asker.municipality';
import {SorreisaMunicipality} from "@config/prod/sorreisa.municipality";
import { SteinkjerMunicipality } from './prod/steinkjer.municipality';
import { RennebuMunicipality } from './prod/rennebu.municipality';
import { OsenMunicipality } from './prod/osen.municipality';
import { FroyaMunicipality } from './prod/froya.municipality';
import { RorosMunicipality } from './prod/roros.municipality';
import { HoltalenMunicipality } from './prod/holtalen.municipality';
import { MidtreGauldalMunicipality } from './prod/midtregauldal.municipality';
import { SkaunMunicipality } from './prod/skaun.municipality';
import { MalvikMunicipality } from './prod/malvik.municipality';
import { MelhusMunicipality } from './prod/melhus.municipality';
import { NamsskoganMunicipality } from './prod/namsskogan.municipality';
import { RoyrvikMunicipality } from './prod/royrvik.municipality';
import { LierneMunicipality } from './prod/lierne.municipality';
import { FrostaMunicipality } from './prod/frosta.municipality';
import { SnasaMunicipality } from './prod/snasa.municipality';
import { VerdalMunicipality } from './prod/verdal.municipality';
import { LekaMunicipality } from './prod/leka.municipality';
import { FlatangerMunicipality } from './prod/flatanger.municipality';
import { SelbuMunicipality } from './prod/selbu.municipality';
import { StjordalMunicipality } from './prod/stjordal.municipality';
import { MerakerMunicipality } from './prod/meraker.municipality';
import { TydalMunicipality } from './prod/tydal.municipality';
import { HoylandetMunicipality } from './prod/hoylandet.municipality';
import { GrongMunicipality } from './prod/grong.municipality';
import { OverhallaMunicipality } from './prod/overhalla.municipality';
import { HitraMunicipality } from './prod/hitra.municipality';
import { HeimMunicipality } from './prod/heim.municipality';
import { OppdalMunicipality } from './prod/oppdal.municipality';
import { RindalMunicipality } from './prod/rindal.municipality';
import { OrklandMunicipality } from './prod/orkland.municipality';
import { OrlandMunicipality } from './prod/orland.municipality';
import { AfjordMunicipality } from './prod/afjord.municipality';
import { IndreFosenMunicipality } from './prod/indrefosen.municipality';
import { LunnerMunicipality } from "@config/prod/lunner.municipality";
import { VestbyMunicipality } from "@config/prod/vestby.municipality";
import { FrognMunicipality } from "@config/prod/frogn.municipality";
import { NittedalMunicipality } from "@config/prod/nittedal.municipality";
import { JevnakerMunicipality } from "@config/prod/jevnaker.municipality";
import { NannestadMunicipality } from "@config/prod/nannestad.municipality";
import { NordreFolloMunicipality } from "@config/prod/nordrefollo.municipality";
import { AsMunicipality } from "@config/prod/as.municipality";
import { LillestromMunicipality } from "@config/prod/lillestrom.municipality";
import { EnebakkMunicipality } from "@config/prod/enebakk.municipality";
import { AurskogHolandMunicipality } from "@config/prod/aurskogholand.municipality";
import { EidsvollMunicipality } from "@config/prod/eidsvoll.municipality";
import { GjerdrumMunicipality } from "@config/prod/gjerdrum.municipality";
import { NesMunicipality } from "@config/prod/nes.municipality";
import { HurdalMunicipality } from "@config/prod/hurdal.municipality";
import { RingerikeMunicipality } from './prod/ringerike.municipality';
import { ValerMunicipality } from "@config/prod/valer.municipality";
import { IndreostfoldMunicipality } from "@config/prod/indreostfold.municipality";
import { RakkestadMunicipality } from "@config/prod/rakkestad.municipality";
import { SkiptvetMunicipality } from "@config/prod/skiptvet.municipality";
import { HvalerMunicipality } from "@config/prod/hvaler.municipality";
import { SarpsborgMunicipality } from "@config/prod/sarpsborg.municipality";
import { FredrikstadMunicipality } from "@config/prod/fredrikstad.municipality";
import { MarkerMunicipality } from "@config/prod/marker.municipality";
import { AremarkMunicipality } from "@config/prod/aremark.municipality";
import { HaldenMunicipality } from "@config/prod/halden.municipality";
import { HamarMunicipality } from './prod/hamar.municipality';
import { RingebuMunicipality } from './prod/ringebu.municipality';
import { RollagMunicipality } from './prod/rollag.municipality';
import { FlesbergMunicipality } from './prod/flesberg.municipality';
import { ModumMunicipality } from './prod/modum.municipality';
import { GolMunicipality } from './prod/gol.municipality';
import { NesbyenMunicipality } from './prod/nesbyen.municipality';
import { FlaMunicipality } from './prod/fla.municipality';
import { HoleMunicipality } from './prod/hole.municipality';
import { LierMunicipality } from './prod/lier.municipality';
import { GeiloMunicipality } from './prod/geilo.municipality';
import { HolMunicipality } from './prod/hol.municipality';
import { SigdalMunicipality } from './prod/sigdal.municipality';
import { KrodsheradMunicipality } from './prod/krodsherad.municipality';
import { VenneslaMunicipality } from './prod/vennesla.municipality';
import { ValerInnlandetMunicipality } from './prod/valerinnlandet.municipality';
import { BydelullernMunicipality } from "@config/prod/bydelullern.municipality";
import { BydelnordreakerMunicipality } from "@config/prod/bydelnordreaker.municipality";
import { BydelsthanshaugenMunicipality } from "@config/prod/bydelsthanshaugen.municipality";
import { BydelostensjoMunicipality } from "@config/prod/bydelostensjo.municipality";
import { BydelvestreakerMunicipality } from "@config/prod/bydelvestreaker.municipality";
import { BydelsondrenordstrandMunicipality } from "@config/prod/bydelsondrenordstrand.municipality";
import { BydelnordstrandMunicipality } from "@config/prod/bydelnordstrand.municipality";
import { BydelbjerkeMunicipality } from "@config/prod/bydelbjerke.municipality";
import { BydelgrorudMunicipality } from "@config/prod/bydelgrorud.municipality";
import { BydelgrunerlokkaMunicipality } from "@config/prod/bydelgrunerlokka.municipality";
import { BydelalnaMunicipality } from "@config/prod/bydelalna.municipality";
import { BydelstovnerMunicipality } from "@config/prod/bydelstovner.municipality";
import { BydelsageneMunicipality } from "@config/prod/bydelsagene.municipality";
import { BoknMunicipality } from './prod/bokn.municipality';
import { SaltdalMunicipality } from './prod/saltdal.municipality';
import { AlMunicipality } from './prod/al.municipality';
import { HemsedalMunicipality } from './prod/hemsedal.municipality';
import { PorsangerMunicipality } from './prod/porsanger.municipality';
import { SaudaMunicipality } from './prod/sauda.municipality';
import { KinnMunicipality } from './prod/kinn.municipality';

export const MUNICIPALITY_CONFIG: MunicipalityConfig = {
  default: DefaultMunicipality,

  arendal: ArendalMunicipality,
  asker: AskerMunicipality,
  afjord: AfjordMunicipality,
  al: AlMunicipality,
  bjerkreim: BjerkreimMunicipality,
  bjornafjorden: BjornafjordenMunicipality,
  bodo: BodoMunicipality,
  baerum: BaerumMunicipality,
  drammen: DrammenMunicipality,
  donna: DonnaMunicipality,
  fauske: FauskeMunicipality,
  forde: FordeMunicipality,
  fla: FlaMunicipality,
  froland: FrolandMunicipality,
  froya: FroyaMunicipality,
  flesberg: FlesbergMunicipality,
  frosta: FrostaMunicipality,
  flatanger: FlatangerMunicipality,
  giske: GiskeMunicipality,
  gol: GolMunicipality,
  geilo: GeiloMunicipality,
  gjesdal: GjesdalMunicipality,
  grong: GrongMunicipality,
  haugesund: HaugesundMunicipality,
  horten: HortenMunicipality,
  holtalen: HoltalenMunicipality,
  hol: HolMunicipality,
  hoylandet: HoylandetMunicipality,
  hitra: HitraMunicipality,
  heim: HeimMunicipality,
  inderoy: InderoyMunicipality,
  indrefosen: IndreFosenMunicipality,
  kautokeino: KautokeinoMunicipality,
  kristiansund: KristiansundMunicipality,
  kristiansand: KristiansandMunicipality,
  kinn: KinnMunicipality,
  kvam: KvamMunicipality,
  kvinesdal: KvinesdalMunicipality,
  kvinnherad: KvinnheradMunicipality,
  krodsherad: KrodsheradMunicipality,
  levanger: LevangerMunicipality,
  luster: LusterMunicipality,
  lorenskog: LorenskogMunicipality,
  'laerdal': LaerdalMunicipality,
  lierne: LierneMunicipality,
  leka: LekaMunicipality,
  lier: LierMunicipality,
  molde: MoldeMunicipality,
  moss: MossMunicipality,
  modum: ModumMunicipality,
  'midt-telemark': MidtTelemarkMunicipality,
  midtregauldal: MidtreGauldalMunicipality,
  melhus: MelhusMunicipality,
  meraker: MerakerMunicipality,
  namsos: NamsosMunicipality,
  namsskogan: NamsskoganMunicipality,
  naroysund: NaroysundMunicipality,
  naustdal: NaustdalMunicipality,
  nesodden: NesoddenMunicipality,
  rade: RadeMunicipality,
  ralingen: RalingenMunicipality,
  rennebu: RennebuMunicipality,
  roros: RorosMunicipality,
  ringerike: RingerikeMunicipality,
  royrvik: RoyrvikMunicipality,
  sandefjord: SandefjordMunicipality,
  senja: SenjaMunicipality,
  ski: SkiMunicipality,
  saltdal: SaltdalMunicipality,
  snillbu: SnillbuMunicipality,
  sokndal: SokndalMunicipality,
  stavanger: StavangerMunicipality,
  sola: SolaMunicipality,
  sula: SulaMunicipality,
  sunnfjord: SunnfjordMunicipality,
  sveio: SveioMunicipality,
  sorum: SorumMunicipality,
  snasa: SnasaMunicipality,
  sigdal: SigdalMunicipality,
  selbu: SelbuMunicipality,
  sauda: SaudaMunicipality,
  stjordal: StjordalMunicipality,
  tonsberg: TonsbergMunicipality,
  tromso: TromsoMunicipality,
  trondheim: TrondheimMunicipality,
  tysnes: TysnesMunicipality,
  tysver: TysverMunicipality,
  tydal:  TydalMunicipality,
  ullensaker: UllensakerMunicipality,
  ulstein: UlsteinMunicipality,
  ulvik: UlvikMunicipality,
  vaksdal: VaksdalMunicipality,
  vennesla: VenneslaMunicipality,
  valerinnlandet: ValerInnlandetMunicipality,
  volda: VoldaMunicipality,
  voss: VossMunicipality,
  ovreeiker: OvreeikerMunicipality,
  oygarden: OygardenMunicipality,
  osen: OsenMunicipality,
  overhalla: OverhallaMunicipality,
  oppdal: OppdalMunicipality,
  orkland: OrklandMunicipality,
  orland: OrlandMunicipality,
  porsanger: PorsangerMunicipality,
  alesund: AlesundMunicipality,
  bydelfrogner: BydelfrognerMunicipality,
  haram: HaramMunicipality,
  hamar: HamarMunicipality,
  hole: HoleMunicipality,
  hemsedal: HemsedalMunicipality,
  sandnes: SandnesMunicipality,
  modalen: ModalenMunicipality,
  malvik: MalvikMunicipality,
  hoyanger: HoyangerMunicipality,
  noreoguvdal: NoreoguvdalMunicipality,
  bydelgamleoslo: BydelgamleosloMunicipality,
  dovre: DovreMunicipality,
  dyroy: DyroyMunicipality,
  randaberg: RandabergMunicipality,
  rindal: RindalMunicipality,
  ringebu: RingebuMunicipality,
  rollag: RollagMunicipality,
  sorreisa: SorreisaMunicipality,
  steinkjer: SteinkjerMunicipality,
  skaun: SkaunMunicipality,
  verdal: VerdalMunicipality,
  lunner: LunnerMunicipality,
  vestby: VestbyMunicipality,
  frogn: FrognMunicipality,
  nittedal: NittedalMunicipality,
  jevnaker: JevnakerMunicipality,
  nannestad: NannestadMunicipality,
  'nordrefollo': NordreFolloMunicipality,
  as: AsMunicipality,
  lillestrom: LillestromMunicipality,
  enebakk: EnebakkMunicipality,
  'aurskog-holand': AurskogHolandMunicipality,
  eidsvoll: EidsvollMunicipality,
  gjerdrum: GjerdrumMunicipality,
  nes: NesMunicipality,
  nesbyen: NesbyenMunicipality,
  hurdal: HurdalMunicipality,
  valer: ValerMunicipality,
  indreostfold: IndreostfoldMunicipality,
  rakkestad: RakkestadMunicipality,
  skiptvet: SkiptvetMunicipality,
  hvaler: HvalerMunicipality,
  sarpsborg: SarpsborgMunicipality,
  fredrikstad: FredrikstadMunicipality,
  marker: MarkerMunicipality,
  aremark: AremarkMunicipality,
  halden: HaldenMunicipality,
  bydelullern: BydelullernMunicipality,
  bydelnordreaker: BydelnordreakerMunicipality,
  bydelsthanshaugen: BydelsthanshaugenMunicipality,
  bydelostensjo: BydelostensjoMunicipality,
  bydelvestreaker: BydelvestreakerMunicipality,
  bydelsondrenordstrand: BydelsondrenordstrandMunicipality,
  bydelnordstrand: BydelnordstrandMunicipality,
  bydelbjerke: BydelbjerkeMunicipality,
  bydelgrorud: BydelgrorudMunicipality,
  bydelgrunerlokka: BydelgrunerlokkaMunicipality,
  bydelstovner: BydelstovnerMunicipality,
  bydelsagene: BydelsageneMunicipality,
  bokn: BoknMunicipality,

  'arendal-dev': ArendalDevMunicipality,
  'asker-dev': AskerDevMunicipality,
  'tonsberg-dev': TonsbergDevMunicipality,
  'voss-dev': VossDevMunicipality,
  'bergen-dev': BergenDevMunicipality,
  'fana-dev': FanaDevMunicipality,
  'hop-dev': HopDevMunicipality,
  'bydelfrogner-dev': BydelfrognerDevMunicipality,

  'bodo-vis': BodoVisMunicipality,
  'tonsberg-vis': TonsbergVisMunicipality,
  'lorenskog-vis': LorenskogVisMunicipality,
  'trondheim-vis': TrondheimVisMunicipality,
  'arendal-vis': ArendalVisMunicipality,
  'tromso-vis': TromsoVisMunicipality,
  'noreoguvdal-vis': NoreoguvdalVisMunicipality,
  'sola-vis': SolaVisMunicipality,
  'samnanger': SamnangerMunicipality,
  'bydelalna': BydelalnaMunicipality,
};

export enum ContractedMunicipality {
  default = 'default',

  arendal = 'arendal',
  asker = 'asker',
  afjord = 'afjord',
  al = 'al',
  bjerkreim = 'bjerkreim',
  bjornafjorden = 'bjornafjorden',
  bodo = 'bodo',
  baerum = 'baerum',
  drammen = 'drammen',
  donna = 'donna',
  fauske = 'fauske',
  forde = 'forde',
  froland = 'froland',
  fla = 'fla',
  froya = 'froya',
  flesberg = 'flesberg',
  frosta = 'frosta',
  flatanger = 'flatanger',
  giske = 'giske',
  gol = 'gol',
  geilo = 'geilo',
  gjesdal = 'gjesdal',
  grong = 'grong',
  haugesund = 'haugesund',
  horten = 'horten',
  hoylandet = 'hoylandet',
  inderoy = 'inderoy',
  indrefosen = 'indrefosen',
  kautokeino = 'kautokeino',
  kristiansund = 'kristiansund',
  kristiansand = 'kristiansand',
  krodsherad = 'krodsherad',
  kinn = 'kinn',
  kvam = 'kvam',
  kvinesdal = 'kvinesdal',
  kvinnherad = 'kvinnherad',
  levanger = 'levanger',
  luster = 'luster',
  lier = 'lier',
  lorenskog = 'lorenskog',
  'laerdal' = 'laerdal',
  lierne = 'lierne',
  leka = 'leka',
  molde = 'molde',
  moss = 'moss',
  modum = 'modum',
  'midt-telemark' = 'midt-telemark',
  midtregauldal = 'midtregauldal',
  melhus = 'melhus',
  namsos = 'namsos',
  meraker = 'meraker',
  namsskogan = 'namsskogan',
  naroysund = 'naroysund',
  naustdal = 'naustdal',
  nesodden = 'nesodden',
  rade = 'rade',
  ralingen = 'ralingen',
  rennebu = 'rennebu',
  roros = 'roros',
  royrvik = 'royrvik',
  ringerike = 'ringerike',
  sandefjord = 'sandefjord',
  senja = 'senja',
  ski = 'ski',
  snillbu = 'snillbu',
  sokndal = 'sokndal',
  stavanger = 'stavanger',
  sola = 'sola',
  sauda = 'sauda',
  sula = 'sula',
  sunnfjord = 'sunnfjord',
  sveio = 'sveio',
  sorum = 'sorum',
  snasa = 'snasa',
  sigdal = 'sigdal',
  selbu = 'selbu',
  stjordal = 'stjordal',
  tonsberg = 'tonsberg',
  tromso = 'tromso',
  trondheim = 'trondheim',
  tysnes = 'tysnes',
  tysver = 'tysver',
  tydal = 'tydal',
  ullensaker = 'ullensaker',
  ulstein = 'ulstein',
  ulvik = 'ulvik',
  vaksdal = 'vaksdal',
  vennesla = 'vennesla',
  valerinnlandet = 'valerinnlandet',
  volda = 'volda',
  voss = 'voss',
  ovreeiker = 'ovreeiker',
  oygarden = 'oygarden',
  osen = 'osen',
  overhalla = 'overhalla',
  oppdal = 'oppdal',
  orkland = 'orkland',
  orland = 'orland',
  porsanger = 'porsanger',
  alesund = 'alesund',
  bydelfrogner = 'bydelfrogner',
  haram = 'haram',
  hamar = 'hamar',
  holtalen = 'holtalen',
  hitra = 'hitra',
  heim = 'heim',
  hole = 'hole',
  hol = 'hol',
  sandnes = 'sandnes',
  modalen = 'modalen',
  malvik = 'malvik',
  hoyanger = 'hoyanger',
  noreoguvdal = 'noreoguvdal',
  bydelgamleoslo = 'bydelgamleoslo',
  dovre = 'dovre',
  dyroy = 'dyroy',
  randaberg = 'randaberg',
  rindal = 'rindal',
  ringebu = 'ringebu',
  rollag = 'rollag',
  samnanger = 'samnanger',
  sorreisa = 'sorreisa',
  steinkjer = 'steinkjer',
  saltdal = 'saltdal',
  skaun = 'skaun',
  verdal = 'verdal',
  lunner = 'lunner',
  vestby = 'vestby',
  frogn = 'frogn',
  nittedal = 'nittedal',
  jevnaker = 'jevnaker',
  nannestad = 'nannestad',
  'nordrefollo' = 'nordrefollo',
  'as' = 'as',
  lillestrom = 'lillestrom',
  enebakk = 'enebakk',
  'aurskog-holand' = 'aurskog-holand',
  eidsvoll = 'eidsvoll',
  gjerdrum = 'gjerdrum',
  nes = 'nes',
  nesbyen = 'nesbyen',
  hurdal = 'hurdal',
  valer = 'valer',
  indreostfold = 'indreostfold',
  rakkestad = 'rakkestad',
  skiptvet = 'skiptvet',
  hvaler = 'hvaler',
  hemsedal = 'hemsedal',
  sarpsborg = 'sarpsborg',
  fredrikstad = 'fredrikstad',
  marker = 'marker',
  aremark = 'aremark',
  halden = 'halden',
  bydelullern = 'bydelullern',
  bydelnordreaker = 'bydelnordreaker',
  bydelsthanshaugen = 'bydelsthanshaugen',
  bydelostensjo = 'bydelostensjo',
  bydelvestreaker =  'bydelvestreaker',
  bydelsondrenordstrand = 'bydelsondrenordstrand',
  bydelnordstrand = 'bydelnordstrand',
  bydelbjerke = 'bydelbjerke',
  bydelgrorud = 'bydelgrorud',
  bydelgrunerlokka = 'bydelgrunerlokka',
  bydelalna = 'bydelalna',
  bydelstovner = 'bydelstovner',
  bydelsagene = 'bydelsagene',
  bokn = 'bokn',

  'arendal-dev' = 'arendal-dev',
  'asker-dev' = 'asker-dev',
  'tonsberg-dev' = 'tonsberg-dev',
  'voss-dev' = 'voss-dev',
  'bergen-dev' = 'bergen-dev',
  'fana-dev' = 'fana-dev',
  'hop-dev' = 'hop-dev',
  'bydelfrogner-dev' = 'bydelfrogner-dev',

  'bodo-vis' = 'bodo-vis',
  'tonsberg-vis' = 'tonsberg-vis',
  'lorenskog-vis' = 'lorenskog-vis',
  'trondheim-vis' = 'trondheim-vis',
  'arendal-vis' = 'arendal-vis',
  'tromso-vis' = 'tromso-vis',
  'noreoguvdal-vis' = 'noreoguvdal-vis',
  'sola-vis' = 'sola-vis',
}

export type MunicipalityConfig = {
  [key in ContractedMunicipality]: UserMunicipality;
};

