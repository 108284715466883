import {UserMunicipality} from '@shared/models/user-municipality.model';

export const BydelgamleosloMunicipality: UserMunicipality = {
  name: 'bydelgamleoslo',
  slogan: {},
  data: {},
  bannerURL: 'https://ucarecdn.com/55f6c002-6fca-409d-905e-709deaeb7242/P1027913_1_Stor.jpeg',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: "Fritidskonto"
}
