import {UserMunicipality} from "@shared/models/user-municipality.model";

export const UlvikMunicipality: UserMunicipality = {
  name: 'ulvik',
  slogan: {
    value: '', id: 'banner.ulvik', meaning: 'bannerSlogan',
    description: 'slogan for ulvik',
    logoURL: '',
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/f22921ce-91a4-4f99-8148-7cb3d7a94f73/friskusbilde.jpg',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
}
